/* eslint-disable sonarjs/cognitive-complexity */
import { useState } from "react";

import { Box, Stack, useMediaQuery } from "@mui/material";
// eslint-disable-next-line no-restricted-imports
import moment from "moment";

import {
  GetRequestsByTimeQuery,
  useGetRequestsByTimeQuery,
} from "shared/generated/graphql";
import { centsToDollars } from "shared/lib/utils";

import {
  getRequestTotalValues,
  transformServiceApproval,
} from "../../../../backend/common/requestTotals";
import { WorkflowType } from "../../../../backend/common/workflow.types";
import InfoCard from "../components/InfoCard";
import PeriodFilter from "../components/PeriodFilter";
import DashboardSection from "../components/dashboard/DashboardSection";

type dashboardSections = {
  total: number;
  count: number;
  name: string;
};

function getRequestValue(request: GetRequestsByTimeQuery["requests"][0]) {
  return getRequestTotalValues({
    request,
    services: transformServiceApproval(request.services) ?? [],
  });
}

export default function Dashboard() {
  const queryMapping = {
    month_to_date: {
      title: "Month to Date",
      start: moment().startOf("month"),
      end: moment().endOf("day"),
    },
    quarter_to_date: {
      title: "Quarter to Date",
      start: moment().startOf("quarter"),
      end: moment().endOf("day"),
    },
    year_to_date: {
      title: "Year to Date",
      start: moment().startOf("year"),
      end: moment().endOf("day"),
    },
    last_month: {
      title: "Last Month",
      start: moment().subtract(1, "month").startOf("month"),
      end: moment().subtract(1, "month").endOf("month"),
    },
    last_quarter: {
      title: "Last Quarter",
      start: moment().subtract(1, "quarter").startOf("quarter"),
      end: moment().subtract(1, "quarter").endOf("quarter"),
    },
    all_time: {
      title: "All Time",
      start: moment("2021-10-01"),
      end: moment().endOf("day"),
    },
  } as any;
  const [rangeKey, setRangeKey] = useState("month_to_date");
  const isMobile = useMediaQuery("(max-width: 1000px)");

  const range = queryMapping[rangeKey];

  const { data, loading } = useGetRequestsByTimeQuery({
    variables: {
      start: range.start.toDate(),
      end: range.end.toDate(),
    },
  });

  const onRangeChanged = (e: any) => {
    const newRangeKey = e.target.value;
    setRangeKey(newRangeKey);
  };

  const fixArrayForTables = (set: any, avgTotal?: boolean): any[] => {
    const newArr: any = [];
    set.forEach((elem: any) => {
      const newElem = elem;
      if (avgTotal)
        newElem["avgDealPrice"] = centsToDollars(newElem.total / newElem.count);
      if (newElem.total) newElem.total = centsToDollars(newElem.total);
      newArr.push(newElem);
    });
    return newArr;
  };

  let totalRevenue;
  let revenueByServiceAdvisor;

  const requests = data?.requests;

  if (!loading && data && data.requests) {
    // exclude SUConnect requests from total revenue calculation
    totalRevenue = centsToDollars(
      requests
        ?.filter((request) => request.workflowType !== WorkflowType.SAAS)
        ?.reduce((acc, cur) => acc + getRequestValue(cur).netRevenue, 0) ?? 0
    );
    revenueByServiceAdvisor = requests?.reduce((acc, cur) => {
      const key = cur.serviceAdvisor?.id ?? 0;
      const { subTotal } = getRequestValue(cur);

      if (acc[key]) {
        acc[key].total += subTotal;
        acc[key].count++;
      } else {
        acc[key] = {
          name:
            key === 0
              ? "Unassigned"
              : `${cur.serviceAdvisor?.firstName} ${cur.serviceAdvisor?.lastName}`,
          total: subTotal,
          count: 1,
        };
      }
      return acc;
    }, [] as dashboardSections[]);
  }

  revenueByServiceAdvisor = revenueByServiceAdvisor
    ? fixArrayForTables(revenueByServiceAdvisor, true)
    : undefined;

  return (
    <>
      {isMobile && (
        <PeriodFilter rangeKey={rangeKey} onRangeChanged={onRangeChanged} />
      )}
      <Stack mx={2}>
        <Box id={"top"} ml={-1}>
          {!isMobile && (
            <PeriodFilter rangeKey={rangeKey} onRangeChanged={onRangeChanged} />
          )}
          <Stack display={"flex"} flexDirection={{ sm: "column", md: "row" }}>
            <InfoCard
              title="Revenue"
              info={Intl.NumberFormat("en-us", {
                style: "currency",
                currency: "USD",
              }).format(Number(totalRevenue) ?? 0)}
            />
            <InfoCard title="Transactions" info={data?.requests?.length ?? 0} />
          </Stack>
        </Box>
        {revenueByServiceAdvisor ? (
          <DashboardSection
            loading={loading}
            boxId={"sa"}
            sectionTitle={"Service Advisor Performance"}
            tableContent={{
              data: revenueByServiceAdvisor,
              columns: [
                { column: "name", name: "Name", overflowCheck: true },
                { column: "total", name: "Total", currency: true },
                { column: "count", name: "Deals" },
                {
                  column: "avgDealPrice",
                  name: "Avg. Deal Price",
                  currency: true,
                },
              ],
              count: revenueByServiceAdvisor.length ?? 0,
              orderBy: "total",
              direction: "asc",
            }}
          />
        ) : null}
      </Stack>
    </>
  );
}
